import { DAY_IN_SECONDS } from '@constants/time';
import { translations } from '@hooks/useTranslations';
import ErrorTemplate from '@templates/ErrorTemplate/ErrorTemplate';
import { Locale } from '@type-declarations/locale';
import { ErrorPage } from '@type-declarations/page';

function CustomErrorPage({ page }: { page: ErrorPage }) {
  return <ErrorTemplate page={page} />;
}

export async function getStaticProps({ locale }: { locale: Locale }) {
  const host = 'generic'; // Only used for 404 and 500 pages

  return {
    props: {
      host,
      locale,
      page: {
        title: translations[locale].errorPageTitle || 'Er is iets fout gegaan',
        seo: {
          metaTitle:
            translations[locale].errorPageTitle || 'Er is iets fout gegaan',
        },
        hero: {
          title:
            translations[locale].errorPageHeroTitle ||
            'Oeps... Het lijkt erop dat er iets is foutgegaan.',
          text:
            translations[locale].errorPageHeroText ||
            'Ga naar onze homepage en zoek opnieuw. Of neem direct event contact op, we helpen je graag verder.',
        },
        sections: [],
      },
      config: {
        footer: null,
        mainMenu: null,
      },
    },
    revalidate: DAY_IN_SECONDS,
  };
}

export default CustomErrorPage;
